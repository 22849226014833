import logo from './logo.svg';
import './App.css';

import React, { useState } from 'react';
import axios from 'axios';

function App() {
  const [num1, setNum1] = useState('');
  const [num2, setNum2] = useState('');
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');

  const handleCalculate = async () => {
    setError('');
    setResult(null);

    // 输入验证
    if (num1 === '' || num2 === '') {
      setError('请输入两个数字。');
      return;
    }

    const number1 = parseInt(num1);
    const number2 = parseInt(num2);

    if (isNaN(number1) || isNaN(number2)) {
      setError('输入的值必须是数字。');
      return;
    }

    if (number1 < 0 || number1 > 1000 || number2 < 0 || number2 > 1000) {
      setError('数字必须在0到1000之间。');
      return;
    }

    try {
      const response = await fetch('http://localhost:8080/api/sum?num1=' + number1 + '&num2=' + number2);
      
      if (!response.ok) {
        throw new Error('网络响应失败');
      }

      const data = await response.json();
      setResult(data);
    } catch (err) {
      setError('计算失败，请检查后端服务是否运行正常。');
      console.error(err);
    }
  };

  return (
    <div className="container">
      <h1>简单加法计算器</h1>
      <div className="input-group">
        <input
          type="number"
          placeholder="输入第一个数字 (0-1000)"
          value={num1}
          onChange={(e) => setNum1(e.target.value)}
        />
        <input
          type="number"
          placeholder="输入第二个数字 (0-1000)"
          value={num2}
          onChange={(e) => setNum2(e.target.value)}
        />
      </div>
      <button onClick={handleCalculate}>计算</button>
      {error && <p className="error">{error}</p>}
      {result !== null && <p className="result">结果：{result}</p>}
    </div>
  );
}

export default App;
